import React, { lazy, Suspense } from 'react'
import Axios from 'axios';
import 'antd/dist/antd.css';
import './css/custom.css';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ManageToken from './custom-hooks/ManageToken';
// import Login from './Pages/Login';
// import Home from './Pages/Layout';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import SetPassword from './components/SetPassword';
import Loader from './Layouts/Loader';
const Login = lazy(()=> import('./Pages/Login'))
const Home = lazy(()=> import('./Pages/Layout'))

function App() {
  const SERVICE_URL = window._env_.REACT_APP_SERVICE_URL;
  let history = useHistory()
  const { token, setToken } = ManageToken();

  // Onetime force logout if 'module_permissions' key not found in local storage
  if (localStorage.getItem("module_permissions") === null && token) {

    Axios.post(
      `${SERVICE_URL}logout`, token,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          "screen-name": "ERP_NO_PERMISSION_HEADER_PAGE",
          "action-name":"PAGE_VIEW",
          "app-code" : "ERP",
          "screen-url": window.location.href
        }
      })
      .then(function (response) {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div className="App">
    <Suspense fallback={<Loader/>}>
      {
        token
          ?
          <Switch>
            <Route path="/">
              <Home history={history} />
            </Route>
          </Switch>
          :
          <Switch>
            <Route exact path="/">
              <Login setToken={setToken} />
            </Route>
            <Route exact path="/password/reset">
              <ForgotPassword />
            </Route>
            <Route exact path="/password/reset/:id">
              <ResetPassword />
            </Route>
            <Route exact path="/password/set-password/:id">
              <SetPassword />
            </Route>
            <Redirect exact to="/" />
          </Switch>
      }
      </Suspense>
    </div>
  );
}

export default App;