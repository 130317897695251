import React from 'react'
import { Form, Input, Button, message } from 'antd';
import {  LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import logo from '../images/logo-SZ.png';
import Axios from 'axios';
const SERVICE_URL = window._env_.REACT_APP_SERVICE_URL;

export default function SetPassword(props) {
    const history = useHistory();
    const error = (response) => {
        message.error(response);
    };
  
    const success = (response) => {
      message.success(response);
    };
  


    const onFinish = (values) => {
          
          const tmppath = history.location.pathname.split('/');
          // console.log(values);
          //return false;

          if(tmppath[3])
          {
            values.token = tmppath[3];
           	// extra data to track 
            const headers = {
              "screen-name": "ERP_SET_NEW_PASSWORD_PAGE",
              "action-name":"PAGE_SUBMIT",
              "app-code" : "ERP",
              "screen-url": window.location.href
            }
            const res = Axios.post(
              `${SERVICE_URL}set-password`,values, {
                headers: headers
              })
              .then(function(response)  {
                if(response.data.status == 'failed') {
                  if(typeof response.data.validation_errors !== 'undefined') {
                    let i = 1;
                    Object.keys(response.data.validation_errors).forEach(key => {
                      if(i==1){
                       message.error(response.data.validation_errors[key][0]);
                      }
                      i++;
                    })
                  }
                } else {
                  success(response.data.message); 
                  history.push('/');
                }
              })
              .catch(er => {
              //  console.log(er)
              });
          }
          
         
    };

    return (
        <div className="login-main">
        <div className="loginbg">
            <div className="loginmn">
              <div className="loginlogo">
                <img src={logo} alt="" />
                <p className="v-text">Admin Panel 2.0</p>
              </div>
              <Form
                name="reset_password"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                >
                <div className="form-label">
                  <h4>Set your password</h4>
                </div>
               
                <Form.Item
                        name="password"
                        rules={[
                          {
                              required: true,
                              message: 'Please input your new password!',
                          },
                        ]}
                    >
                        <Input.Password 
                         prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        />
                </Form.Item>
                <Form.Item
                        
                        dependencies={['password']}
                        name="password_confirmation"
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm the password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
              
                            return Promise.reject(new Error('Confirm password does not match!'));
                          },
                        }),
                        ]}
                    >
                        <Input.Password 
                         prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Confirm Password"
                        />
                </Form.Item>
               <p className="left-text">Your password should contains at least 8 characters with 1 Uppercase 1 Lowercase 1 Numeric and 1 special character</p>   
              <Form.Item className="form-bottom-info">
                  <Button style={{float:"left"}} type="primary" htmlType="submit" className="login-form-button">
                   Submit
                  </Button>
                  
              </Form.Item>
              
              </Form>
               
            </div>
        </div>
    </div>
    )
}
