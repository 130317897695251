import React, { useEffect } from 'react';
import Axios from 'axios';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.logErrorToFile(error, errorInfo);
  }

  /**
   * Log the error
   */
  logErrorToFile(error, errorInfo) {
    const token = localStorage.getItem('token');
    let values ={
        application_code:'erp',
        level:'critical',
        trace: {'error_log':errorInfo,'error_info': error.message},
        screen_url: window.location.href
    };
    const SERVICE_URL = window._env_.REACT_APP_SERVICE_URL;
    Axios.post(
        `${SERVICE_URL}log-exception-data`,values,
        {headers: {
            'Authorization': `Bearer ${token}`
        }})
        .then((response) =>  {
          if(response.data.status != 'success') {
            console.log(response.data.message);
          } else {
            console.log(response.data.message);
          }
        })
        .catch(er => {
            console.log(er);
        });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback />;
    }
    return this.props.children; 
  }
}

const ErrorFallback = () => {
  
  useEffect(() => {
    const timer = setTimeout(() => {
     window.location.href = '/';
    }, 5000); // Redirect after 5 seconds
    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div>
      <center><h1>Something went wrong.You will be redirected to the dashboard in 5 seconds.</h1></center>
    </div>
  );
};


export default ErrorBoundary;